import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '../components/Typography';
import Button from '../components/Button';
import FacebookIcon from '@material-ui/icons/Facebook';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
    paddingBottom: 0,
  },
  cover: {
    width: 210,
    height: 'auto',
  },
  dateInfo: {
    fontSize: 14,
  },
  image: {
    height: 250,
    maxWidth: '100%',
  },
  dialogTitle: {
    textAlign: 'center'
  }
});

function SidebarSchedule(props) { 
  const { classes, events } = props;
  const [open, SetOpen] = React.useState(false);
  const [imgURL, SetImgUrl] = React.useState('');
  const [fb, SetFBUrl] = React.useState('');
  const [eventTitle, SetEventTitle] = React.useState('');
  const [ticketsURL, SetTicketsUrl] = React.useState('');
  const [eventId, SetEventId] = React.useState('');
  

  const openModal  = (index) => {
    console.log("TRYING TO OPEN THIS");
    console.log(index);
    let url = `${process.env.REACT_APP_CDN_FLYER_URL}/${new Date(index.event.date).getFullYear()}/${index.event.flyer_img}`
    SetOpen(true);
    SetImgUrl(url);
    SetFBUrl(fb);
    SetEventTitle(index.event.title); 
    SetTicketsUrl(index.event.tickets); 
    SetEventId(index.event.eventId);
  };

  const closeModal  = () => {
    SetOpen(false);
  }

  return (

    <Grid container spacing={4}>
      {events.map((event) => (
        <Grid item xs={12} key={event.eventId+"g"}>
          <CardActionArea component="a" onClick={() => openModal({ event })} value={event} sx={{ width: 'auto' }} key={event.eventId+" c"}>
            <Card className={classes.root} variant="outlined" key={event.eventId+" thumbnail"}>
              <CardMedia
                className={classes.cover}
                image={`${process.env.REACT_APP_CDN_FLYER_URL}/${new Date(event.date).getFullYear()}/thumb-${event.flyer_img}`}
                title={event.title}
              />
              <div className={classes.details}>
                <CardContent className={classes.content}>
                  <Typography component="h6" variant="h6">
                    {event.title}
                  </Typography>
                  <Typography color="textSecondary"  className={classes.dateInfo} gutterBottom>
                    {new Date(event.date).toLocaleDateString("en-US")}
                  </Typography>
                  {event.tickets !== "" ?  
                  <Button variant="outlined" color="secondary" startIcon={<LocalActivityIcon />} href={event.tickets} size="small"> 
                  Tickets  </Button> : <p></p>}
                </CardContent>
              </div>
            </Card>
          </CardActionArea>
        </Grid>
      ))}

    <div className="fb-page" data-href={process.env.REACT_APP_SOCIAL_FB} data-tabs="timeline, events" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="true" data-show-facepile="false">
      <blockquote cite={process.env.REACT_APP_SOCIAL_FB} className="fb-xfbml-parse-ignore">
        <a href={process.env.REACT_APP_SOCIAL_FB}>World Drag Racing Circuit</a>
      </blockquote>
    </div>
    
    <Dialog open={open} onClose={closeModal}  aria-labelledby="view-large" key={eventId} maxWidth="lg">
      <DialogTitle className={classes.dialogTitle}>
        {eventTitle}
      </DialogTitle>
      <DialogContent>

        <img
            width="100%"
            alt="Alternative Description"
            src={imgURL}
          />
          <br/>
        
      </DialogContent>

      <DialogActions className={classes.root}>
          {ticketsURL !== "" ?  
          <Button variant="contained" color="secondary" href={ticketsURL} startIcon={<LocalActivityIcon />} >
            Buy Tickets
          </Button> : <Button variant="outlined" color="secondary" startIcon={<LocalActivityIcon />} disabled>Soon Come </Button>}
          {fb !== "" ?  
          <Button variant="outlined" color="secondary" href={fb} startIcon={<FacebookIcon />} >
            Event Page
          </Button> : <p></p> }
          <Button onClick={closeModal}>Close</Button>
      </DialogActions>
      
      </Dialog>
  </Grid>
  );
}

SidebarSchedule.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SidebarSchedule);


