import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { ImageList, ImageListItem } from '@material-ui/core';
import Container from '@material-ui/core/Container';
// import Typography from '@material-ui/core/Typography';
import {Dialog, DialogTitle, DialogActions, DialogContent, Button} from '@material-ui/core';
// import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import ListSubheader from '@material-ui/core/ListSubheader';

import { db } from "../firebase";
import { query, collection, doc, getDoc, getDocs, orderBy, where } from "firebase/firestore";
const cdn = process.env.REACT_APP_CDN_FLYER_URL;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    marginBottom: '10em'
  },
  gridList: {
    transform: 'translateZ(0)',
  },
  listing: {
    marginTop: theme.spacing(2),
  },
  img: {
    width: '100%',
    border: '1px solid #ccc'
  },
  media: {
    height: 0,
    width: '100%',
    backgroundSize: 'contain',
    paddingTop: '120%', // 16:9
  }
});

function Gallery(props) {
  
  const [showModal, setShowModal] = useState(false);
  const [imgURL, setImgURL] = useState("");
  const { classes } = props;

  function openModal(y,index) {
    setShowModal(true);
    setImgURL(`${cdn}/${y}/${index}`);
  }

  function closeView() {
    setShowModal(false);
    setImgURL('');
  }

  const [events, setEvents] = useState([]);
  const [imgSchedule2023, setFlyerImages] = useState([]);
  useEffect(() => {
    getUpcomingEvents();
    getTopSectionImages();
  }, []);

  const getUpcomingEvents = async (docId) => {
    try {
  
        let eventList = [];
        let today = new Date();
        let cutoff = today.setDate(today.getDate() - 1); // cut off 1 days later
        let serverDate = new Date(cutoff).toISOString();
        const querySnapshot = await getDocs(query(collection(db, "events"), where("date", ">=", serverDate), orderBy('date', 'asc') ));  // filter 
        querySnapshot.forEach((doc) => { 
          eventList.push({
            eventId: doc.id,
            date: doc.data().date,
            description: doc.data().description,
            facebook: doc.data().facebook,
            flyer_img: doc.data().flyer_img,
            region: doc.data().region,
            tickets: doc.data().tickets,
            title: doc.data().title,
            venue_name: doc.data().venue_name,
            venue_address: doc.data().venue_address,
            active: doc.data().display
          });
        });

        setEvents(eventList);

    } catch (err) {
      console.error(err);
    }
  };

  const getTopSectionImages = async (docId) => {
    try {
      let querySnapshot = await getDoc(doc(db, "site_configuration", "gallery_flyers") );  // filter
      let imageList = querySnapshot.data()["01_schedules"];
      setFlyerImages(imageList);
    } catch (err) {
      console.error(err);
    }
  };
  

  // render() {
    

    return (<>
    <Container className={classes.root} component="section">
      <ImageList rowHeight={'auto'} className={classes.gridList} gap={1}  cols={12}> 
        <ImageListItem key="Subheader1" cols={12} style={{ height: 'auto' }}>
          <ListSubheader component="div">Schedule</ListSubheader>
        </ImageListItem>
        {imgSchedule2023.map((flyer,i)=> (
          <ImageListItem key={`${i},flyer_schedule`} cols={6}>
            <img src={`${cdn}/2023/${flyer}`} 
              alt={'Racers & Tuners World Drag Racing Circuit Schedule'}
              className={classes.img} />
          </ImageListItem>
        ))}

        <ImageListItem key="Subheader2" cols={12} style={{ height: 'auto' }}>
          <ListSubheader component="div">Event Flyers</ListSubheader>
        </ImageListItem>
          {events.filter((e) => e.flyer_img !== 'wdrc.jpg' ).map((item,idx) => (

            <ImageListItem key={item.id} cols={item.cols || 3} rows={item.cols>3 ? 3 : 2}
            onClick={() => openModal(new Date(item.date).getFullYear(), item.flyer_img)} >
              <img src={`${cdn}/${new Date(item.date).getFullYear()}/thumb-600-${item.flyer_img}`} 
                alt={item.title} 
                className={classes.img} />
              {/* <ImageListItemBar
                  title={displayLocalDate(item.date) + item.title}
                  subtitle={<span>{item.venue_name}</span>}
                  actionIcon={
                    <IconButton aria-label={`info about ${item.title}`} className={classes.icon}>
                      <InfoIcon />
                    </IconButton>
                  }
                /> */}

            </ImageListItem>

          ))} 
        </ImageList> 

        {/* <Grid container spacing={2} className={classes.listing}> 
            <Grid item sm={6} md={4}  key={'g'+item.eventId}>
            <Card className={classes.root}>
              <CardActions onClick={() => this.openModal(item.flyer_img,new Date(item.date).getFullYear())}>
                <CardMedia
                className={classes.media}
                image={`${cdn}/${new Date(item.date).getFullYear()}/${item.flyer_img}`} 
                title={item.title}
                alt={item.title} 
                />
              </CardActions>
            </Card>
            </Grid>
             */}
    </Container>
                <br/>

    <Dialog open={showModal} onClose={closeView}  aria-labelledby="view-large" key={""} maxWidth="xl">
      <DialogTitle>
        
      </DialogTitle>
      <DialogContent>

        <img
            width="100%"
            alt="Alternative Description"
            src={imgURL}
          />
        
      </DialogContent>
      <DialogActions className={""}>
        <Button onClick={closeView}>Close</Button>
      </DialogActions>
    </Dialog>
    </>
    )
  // }
}

export default withStyles(styles)(Gallery);