import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import {Box, Card, CardHeader, CardActionArea, CardActions, CardMedia, CardContent} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import RoomIcon from '@material-ui/icons/Room';
import FacebookIcon from '@material-ui/icons/Facebook';
import EventIcon from '@material-ui/icons/Event';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

import { db } from "../firebase";
import { query, collection, getDocs, orderBy, where } from "firebase/firestore";

const cdn = process.env.REACT_APP_CDN_FLYER_URL;

const styles = (theme) => ({
  card: {
    // display: 'flex',
  },
  cardDetails: {
    flex: 1,
    width: '100%'
  },
  cardMedia: {
    width: 160,
    height: 'auto',
  },
  mainGrid: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  cover: {
    height: 0,
    width: '100%',
    backgroundSize: 'contain',
    paddingTop: '120%', // 16:9
    // paddingTop: '56.25%', // 16:9
    // height: 'auto',
    // minHeight: 150
  },
  listing: {
    marginTop: theme.spacing(2),
  },
  dialogStyle: {
    maxWidth: '80%',
    // width: '50%'
  },
  b_float_meta: {
    width: '50px',
    border: '1px solid #ececec',
    float: 'left',
    textAlign: 'center',
    fontSize: '14px',
    borderRadius: '3px',
    lineHeight: '20px',
    color: '#767676',
    padding: '4px',
    margin: '0 8px 0 0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  b_float_meta_day: {
    color: '#000000',
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 500,
  },
  centered: {
    borderTop: '1px solid #ececec',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  months: {
    fontWeight: '300',
    color: '#767676',
  }
});

function dayOfMonth(d){
  return new Date(d).toLocaleDateString("en-US", { day: "2-digit" })
}

function displayMonth(d){
  return new Date(d).toLocaleDateString("en-US", { month:"short" }).toUpperCase()
}

function displayLocalDate(d){
  return (
    <Typography variant="subtitle2" color="textSecondary">
      <EventIcon fontSize="small" color="action" /> 
        {new Date(d).toLocaleDateString("en-US", 
              { timeZone: 'America/New_York'
              , day: '2-digit'
              , month: 'long'
              , year: 'numeric'
              })}
    </Typography>
  );
}

// class schedule extends Component {
function Schedule(props){
  const [events, setEvents] = useState([]);
  const [months, setMonths] = useState([]);
  const [monthFilter, setMonthFilter] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [eventDetails, setEventDetails] = useState({});

  const getUpcomingEvents = async (docId) => {
    try {
  
        let eventList = [];
        let today = new Date();
        let cutoff = today.setDate(today.getDate() - 1); // cut off 1 days later
        let serverDate = new Date(cutoff).toISOString();
        const querySnapshot = await getDocs(query(collection(db, "events"), where("date", ">=", serverDate), (orderBy('date', 'asc')) ));  // filter 
        querySnapshot.forEach((doc) => { 
          eventList.push({
            eventId: doc.id,
            date: doc.data().date,
            description: doc.data().description,
            facebook: doc.data().facebook,
            flyer_img: doc.data().flyer_img,
            region: doc.data().region,
            tickets: doc.data().tickets,
            title: doc.data().title,
            venue_name: doc.data().venue_name,
            venue_address: doc.data().venue_address,
            active: doc.data().display,
            cols: doc.data().cols
          });
        });

        setEvents(eventList);

        setMonths(eventList.map((event) => displayMonth(event.date)));

    } catch (err) {
      console.error(err);
    }
  };

  
  function openModal(index) {
    let url = `${cdn}/${new Date(index.event.date).getFullYear()}/${index.event.flyer_img}`;
    
    setEventDetails({
      showModal: true,
      imgURL: url,
      eventTitle: index.event.title,
      facebook: index.event.facebook,
      tickets: index.event.tickets,
      venue_name: index.event.venue_name
    });

    setShowModal(true);
  }

  function closeModal() {
    setEventDetails({
      imgIndex: 0,
      rotation: 0
    });
    setShowModal(false);
  }

  // function nextClick() {
  //   setEventDetails({
  //     imgIndex: 0,
  //     rotation: 0,
  //     prevState: (prevState) => {
  //       return {
  //         imgIndex:
  //           prevState.imgIndex === prevState.imgUrlLength - 1
  //             ? 0
  //             : prevState.imgIndex + 1
  //       };
  //     }
  //   });
  //   this.setState(prevState => {
  //     return {
  //       imgIndex:
  //         prevState.imgIndex === prevState.imgUrlLength - 1
  //           ? 0
  //           : prevState.imgIndex + 1
  //     };
  //   });
  // }

  function setFilter(month){
    setMonthFilter(month);
  }

  // function prevClick() {
  //   this.setState(prevState => {
  //     return {
  //       imgIndex:
  //         prevState.imgIndex === 0
  //           ? prevState.imgUrlLength - 1
  //           : prevState.imgIndex - 1
  //     };
  //   });
  // }
  
  useEffect(() => {
    getUpcomingEvents();
  }, []);

  const { classes } = props;
  
  const displayEvents = monthFilter === ""       //this.state.filterByMonth === '' 
                          ? events
                          : events.filter((event) => displayMonth(event.date) === monthFilter );
  
  const nth = function(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  }
  
    
    return (
      <>
      <main>
          {/* <MainFeaturedPost post={mainFeaturedPost} /> */}
          <Grid container spacing={5} className={classes.mainGrid}>
          
            <Grid item xs={12}>
            <Typography variant="p" gutterBottom>
                  Tickets can be purchased from <a target="_blank" href="https://tickets.thefoat.com/Worlddragracingcircuit">https://tickets.thefoat.com/Worlddragracingcircuit</a>
            </Typography>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.sidebarAboutBox}>
                <Typography variant="h6" gutterBottom>
                  Events
                </Typography>

                <Divider />

                <div className={classes.centered}>
                  <Hidden smDown>
                    <Button variant="text" className={classes.months} color="secondary" onClick={() => setFilter('')}>
                    {months.length} events
                    </Button>
                  </Hidden>
                  {Array.from(new Set(months)).map((mm) => 
                    <Button variant="text" className={classes.months} color="secondary" onClick={() => setFilter(mm)} key={mm}>
                      {mm} <Hidden smDown>({months.filter(value => value===mm).length} { months.filter(value => value===mm).length === 1 ? "event" : "events" }) </Hidden>
                      
                      {/* ({displayEvents
                          .filter(e => new Date(e.date).toLocaleDateString("en-US", { month:"short" }).toUpperCase()===mm )
                          .map(e =>  new Date(e.date).toLocaleDateString("en-US", { month:"short", day: 'numeric' }) )
                          .join(", ")
                      }) */}
                    </Button> 
                  )}
                  <Hidden smDown>
                    <Button variant="text" className={classes.months} color="secondary" onClick={() => setFilter('')}>
                    View all
                  </Button>
                  </Hidden>
                </div>


                <Grid container spacing={2} className={classes.listing}> 
                {displayEvents.map((event) => (
                <Grid item sm={6} md={4}  key={'g'+event.eventId}>
                  <Card className={classes.card} variant="outlined" key={event.eventId}>
                    <CardHeader
                      title={<><Box component="div" className={classes.b_float_meta}>
                      {displayMonth(event.date)}
                        <Box component="div" className={classes.b_float_meta_day}>
                          {dayOfMonth(event.date)}
                          {/* {nth(event.date)} */}
                        </Box>
                      </Box>
                      <Typography component="h6" variant="h6">
                          {event.title} { event.city ? '(' + event.title + ')' : ''}
                      </Typography></>}
                      subheader={<Typography variant="subtitle1" color="textSecondary" gutterBottom>{(event.venue_name) ? event.venue_name  : <p> </p>}
                        </Typography>}
                    />
                    <CardActionArea component="a" onClick={() => openModal({ event })} value={event} sx={{ width: 'auto' }}>
                    <CardMedia
                      className={classes.cover}
                      image={`${cdn}/${new Date(event.date).getFullYear()}/thumb-600-${event.flyer_img}`}
                      title={event.title}
                    />  
                      <Hidden xlDown>
                        <CardMedia className={classes.cardMedia} 
                                  image={`${cdn}/${new Date(event.date).getFullYear()}/${event.flyer_img}`}
                                  component="img"
                                  title={event.title} />
                      </Hidden>
                      </CardActionArea>
                      <CardContent className={classes.cardDetails}>
                        {displayLocalDate(event.date)}
                        <Typography variant="subtitle2">
                          <RoomIcon fontSize="small" color="primary" /> 
                          { (event.venue_name) ? event.venue_name  : 'To be announced' }
                          <Typography variant="caption" color="textSecondary">
                          { (event.venue_address) ? ' ' + event.venue_address  : ' ' }
                        </Typography>
                        </Typography> 
                      </CardContent>
                      <CardActions className={classes.centered}>
                          {event.tickets !== "" ?
                          <Button variant="contained" color="secondary" startIcon={<LocalActivityIcon />} href={event.tickets}> 
                            Buy Tickets  </Button>  : 
                          <Button variant="contained" color="secondary" startIcon={<LocalActivityIcon />} disabled>Soon Come </Button>}

                          {event.facebook !== "" ?
                          // <IconButton color="primary" href={event.facebook} variant="outlined"> <FacebookIcon /> </IconButton> 
                          <Button variant="contained" color="secondary" startIcon={<FacebookIcon />} href={event.facebook}> 
                            Event Page  </Button>
                          : '' }
                      </CardActions>
                    </Card>
                  
                </Grid>
                ))}
                </Grid>
              </Paper>

            </Grid>

          </Grid>

          <Dialog open={showModal} onClose={closeModal}  aria-labelledby="view-large" key={eventDetails.eventId} maxWidth="lg">
          <DialogTitle>
            {eventDetails.eventTitle}
          </DialogTitle>
          <DialogContent>

            <img
                width="100%"
                alt="Alternative Description"
                src={eventDetails.imgURL}
              />
            
            </DialogContent>
            <DialogActions className={classes.root}>
            {eventDetails.tickets !== "" ?  
            <Button variant="contained" color="secondary" href={eventDetails.tickets} startIcon={<LocalActivityIcon />} >
              Buy Tickets
            </Button> : <Button variant="outlined" color="secondary" startIcon={<LocalActivityIcon />} disabled>Soon Come </Button>}
            {eventDetails.facebook !== "" ?  
            <Button variant="outlined" color="secondary" href={eventDetails.facebook} startIcon={<FacebookIcon />} >
              Event Page
            </Button> : <p></p> }
              <Button onClick={closeModal}>Close</Button>
            </DialogActions>
          </Dialog>
        </main>

      </>
    )
}

export default withStyles(styles)(Schedule);

