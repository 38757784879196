import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Paper from '@material-ui/core/Paper';

import ProductCTA from '../modules/views/ProductCTA';
import EventSeries from '../modules/views/EventSeries';
import SidebarSchedule from '../modules/views/SidebarSchedule';
import Typography from '../modules/components/Typography';
import {Dialog, DialogTitle, DialogActions, DialogContent, Button} from '@material-ui/core';

import { db } from "../firebase";
import { query, collection, getDocs, orderBy, limit, where } from "firebase/firestore";
const cdn = process.env.REACT_APP_CDN_RESULTS_URL;

const styles = (theme) => ({
  root: {

  },
  mainGrid: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  image: {
    width: '100%',
  },
  sidebarSection: {
    marginTop: theme.spacing(3),
  },
});

function ResultEventBlock({imgurl, onClick, styleName}) {
  return (
      
        <Grid item xs={12} md={4}>
          <Card className="resultblockRoot">
            <CardActions onClick={onClick}>
              <CardMedia
              className="resultblockMedia"
              image={`${process.env.REACT_APP_CDN_RESULTS_URL}/p/thumb-${imgurl}.jpg`}
              title="results"
              />
            </CardActions>
          </Card>
        </Grid>
  );
}

function RaceResults(props){
  const [events, setEvents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [imgURL, setImgURL] = useState("");
  const { classes } = props;
  
  useEffect(() => {
    getUpcomingEvents1();
  }, []);

  const getUpcomingEvents1 = async (docId) => {
    try {
  
        let eventList = [];
        let today = new Date();
        let cutoff = today.setDate(today.getDate() - 1); // cut off 1 days later
        let serverDate = new Date(cutoff).toISOString();
        const querySnapshot = await getDocs(query(collection(db, "events"), where("date", ">=", serverDate), orderBy('date', 'asc'), limit(8) ));  // filter 
        querySnapshot.forEach((doc) => { 
          eventList.push({
            eventId: doc.id,
            date: doc.data().date,
            description: doc.data().description,
            facebook: doc.data().facebook,
            flyer_img: doc.data().flyer_img,
            region: doc.data().region,
            tickets: doc.data().tickets,
            title: doc.data().title,
            venue_name: doc.data().venue_name,
            venue_address: doc.data().venue_address,
            active: doc.data().display
          });
        });

        setEvents(eventList);

    } catch (err) {
      console.error(err);
    }
  };

  function openModal(index) {
    setShowModal(true);
    setImgURL(`${cdn}/p/${index}.jpg`);
  }

  function closeView() {
    setShowModal(false);
    setImgURL('');
  }
  

    return (
      <>
      <main>
        <Grid container spacing={5} className={classes.mainGrid}>
          <Grid item xs={12} md={8}>
            
            <Typography variant="h1">
              Results
            </Typography>
            <Divider />

            <Paper align="center" className={classes.mainGrid} elevation={0}>
            <Typography variant="h3" gutterBottom>
              2019
            </Typography>
            </Paper>

            <Grid container spacing={5}>
            <ResultEventBlock imgurl="20190303rwlaresults" onClick={() => openModal("20190303rwlaresults")} 
                              className={classes.media2}  />
            </Grid>
            
            
            <Paper align="center" className={classes.mainGrid} elevation={0}>
              <Typography variant="h3" gutterBottom>
                2018
              </Typography>
            </Paper>

            <Grid container spacing={5}>
            <ResultEventBlock imgurl="20181111rwlaresults" 
                              onClick={() => openModal("20181111rwlaresults")} 
                              className={classes.media2}  />
            
            <ResultEventBlock imgurl="20180304rwlaresults_modernsuperstreet" 
                              onClick={() => openModal("20180304rwlaresults_modernsuperstreet")} 
                              className={classes.media2}  />
            
            <ResultEventBlock imgurl="20180304rwlaresults_FWD16" 
                              onClick={() => openModal("20180304rwlaresults_FWD16")} 
                              className={classes.media2}  />
            
            <ResultEventBlock imgurl="20180304rwlaresults_i1" 
                              onClick={() => openModal("20180304rwlaresults_i1")} 
                              className={classes.media2}  />

            <ResultEventBlock imgurl="20180304rwlaresults_i3" 
                              onClick={() => openModal("20180304rwlaresults_i3")} 
                              className={classes.media2}  />

            <ResultEventBlock imgurl="20180304rwlaresults_b1" 
                              onClick={() => openModal("20180304rwlaresults_b1")} 
                              className={classes.media2}  />
            <ResultEventBlock imgurl="20180304rwlaresults_b2" 
                              onClick={() => openModal("20180304rwlaresults_b2")} 
                              className={classes.media2}  />
                        <ResultEventBlock imgurl="20180304rwlaresults_b1" 
                              onClick={() => openModal("20180304rwlaresults_b3")} 
                              className={classes.media2}  />
            </Grid>

            <Paper align="center" className={classes.mainGrid} elevation={0}>
              <Typography variant="h3" gutterBottom>
                2017
              </Typography>
            </Paper>
            
            <Grid container spacing={5}>
            <ResultEventBlock imgurl="2017-raceresults" onClick={() => openModal("2017-raceresults")} 
                              className={classes.media2}  />
            </Grid>

          </Grid>

          <Grid item xs={12} md={4}>
          <Paper elevation={0} className={classes.sidebarAboutBox}>
            <Typography variant="h6">
                Schedule
            </Typography>
            <Divider />
            <Typography>
              &nbsp;
            </Typography>

            <SidebarSchedule events={events} />

          </Paper>
          </Grid>
        </Grid>
        
        <Dialog open={showModal} onClose={closeView}  aria-labelledby="view-large" key={imgURL} maxWidth="xl">
          <DialogTitle>
            
          </DialogTitle>
          <DialogContent>

            <img
                width="100%"
                alt="Alternative Description"
                src={imgURL}
              />
            
          </DialogContent>
          <DialogActions className={classes.root}>
            <Button onClick={closeView}>Close</Button>
          </DialogActions>
        </Dialog>
      </main>
      <Divider />
      <EventSeries />
      <Divider />
      <ProductCTA />
      
      </>
    )
}

export default withStyles(styles)(RaceResults);