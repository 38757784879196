import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import {
  // arrayUnion,
  doc,
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  // update,
  orderBy,
  updateDoc,
  // serverTimestamp,
  Timestamp
} from "firebase/firestore";

import { getStorage } from "firebase/storage";

// import { getDatabase, ref, set } from "firebase/database";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCm3aCZqCdC1-aq99I2uG-GRO_nDqZVDZQ",
  authDomain: "wdrc-events.firebaseapp.com",
  databaseURL: "https://wdrc-events-default-rtdb.firebaseio.com",
  projectId: "wdrc-events",
  storageBucket: "wdrc-events.appspot.com",
  messagingSenderId: "799765047440",
  appId: "1:799765047440:web:f608a007e4cc017b8f9015",
  measurementId: "G-L9B5E0SG3L"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
export const storage = getStorage(app);
// const database = getDatabase(app);


const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const registerWithEmailAndPassword = async (fName, lName, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      first: fName,
      last: lName,
      name: `${fName} ${lName}`,
      authProvider: "local",
      email: email,
      createdAt: Timestamp.now()
      // instagram: ig
    });
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    // alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};


const addEvent = async (eventDate, eventTitle, flyerImg, ticketUrl, fbUrl, region, desc, pinned, venueName, venueAddr, cols ) => {
  try {
    let formattedDate = new Date(eventDate).toISOString();
    let serverDate = new Date().toISOString();
    const newEvent = {
      title: eventTitle,
      date: formattedDate || serverDate,
      flyer_img: flyerImg || "",
      tickets: ticketUrl || "",
      facebook: fbUrl || "",
      region: region | "",
      description: desc || "",
      pinned: pinned || false,
      venue_name: venueName || "",
      venue_address: venueAddr || "",
      cols: cols || 3,
      display: true
    };

    await addDoc(collection(db, 'events'), newEvent)
    .catch((err) => {
      console.log(err)
    });
                
  } catch (err) {
    console.error(err);
  }
};

const updateEvent = async (docId, eventDate, eventTitle, flyerImg, ticketUrl, fbUrl, region, desc, pinned, venueName, venueAddr, cols ) => {
  try {
    let formattedDate = new Date(eventDate).toISOString();
    let serverDate = new Date().toISOString();
    const newEvent = {
      title: eventTitle,
      date: formattedDate || serverDate,
      flyer_img: flyerImg || "",
      tickets: ticketUrl || "",
      facebook: fbUrl || "",
      region: region | "",
      description: desc || "",
      pinned: pinned || false,
      venue_name: venueName || "",
      venue_address: venueAddr || "",
      cols: cols || 3,
      display: true
    };
    await updateDoc(doc(collection(db, 'events'), docId), newEvent)
    .catch((err) => {
      console.log(err)
    });
                
  } catch (err) {
    console.error(err);
  }
};

// get all events
const getAllEvents = async (docId) => {
  try {

      let eventList = [];
      const querySnapshot = await getDocs(query(collection(db, "events"), (orderBy('date', 'desc')))); 
      querySnapshot.forEach((doc) => { 
        eventList.push({
          eventId: doc.id,
          date: doc.data().date,
          description: doc.data().description,
          facebook: doc.data().facebook,
          flyer_img: doc.data().flyer_img,
          region: doc.data().region,
          tickets: doc.data().tickets,
          title: doc.data().title,
          venue_name: doc.data().venue_name,
          venue_address: doc.data().venue_address,
          active: doc.data().display,
          cols: doc.data().cols
        });
      });

      return eventList;
                
  } catch (err) {
    console.error(err);
  }
};
// get upcoming events
const getUpcomingEvents = async (docId) => {
  try {

      let eventList = [];
      const querySnapshot = await getDocs(query(collection(db, "events"), (orderBy('date', 'desc')) ));  // filter 
      querySnapshot.forEach((doc) => { 
        eventList.push({
          eventId: doc.id,
          date: doc.data().date,
          description: doc.data().description,
          facebook: doc.data().facebook,
          flyer_img: doc.data().flyer_img,
          region: doc.data().region,
          tickets: doc.data().tickets,
          title: doc.data().title,
          venue_name: doc.data().venue_name,
          venue_address: doc.data().venue_address,
          active: doc.data().display,
          cols: doc.data().cols
        });
      });
      return eventList;            
  } catch (err) {
    console.error(err);
  }
};

// get event
const getEvent = async (docId) => {
  try {
    const q = query(collection(db, "events"), where("eventId", "==", docId));
    const doc = await getDocs(q);
    const data = doc.docs[0].data();
    return data;
  } catch (err) {
    console.error(err);
  }
};

// get upcoming flyers

// const loadDataLoop = async () => {
//   var json = require('./upload.json'); 
//   try {
//     const r2xRef = collection(db, "events");
//     for (var i=0; i < json.length; i++) {
//       await Promise.all([
//         addDoc(collection(r2xRef), json[i])
//       ]);
//       // console.log(i, json[i]);
//     } 
//   } catch (err) {
//     console.error(err);
//   }
// };

export {
  auth,
  db,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  addEvent,
  updateEvent,
  getAllEvents,
  getUpcomingEvents,
  getEvent,
  // loadDataLoop,
  logout,
};
